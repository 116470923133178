import React from "react";

class HeroTitle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isReady: true
      });
    }, 600);
  }

  render() {
    const { children, ...props } = this.props;

    return (
      <div className={`hero-title ${this.state.isReady ? "is-loaded" : ""}`}>
        <h1 {...props}>{this.props.children}</h1>
      </div>
    );
  }
}

export default HeroTitle;
